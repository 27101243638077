import Rules from "../rules";
import React from "react";
import OverflowStyled from "../OverflowStyled";
import { ColoredImage } from "../ColoredImage";

export interface Weapon {
  "Name": string;
  "Book"?: string;
  "Prefix"?: string;
  "Name with Stock": string;
  "Name without Stock": string;
  "Is Modded"?: boolean;
  "Weapon Type": string;
  "Damage Rating": number;
  "Damage Type": string;
  "Rate of Fire"?: number;
  "Qualities": Record<string, number>;
  "Weight": string;
  "Cost": number;
  "Range": string;
  "Rarity": number;
  "Rules": string;
  "Ammo": string;
  "Ammo Cost"?: string;
  "Ammo Rarity"?: string;
  "Ammo Alts"?: string[];
  "Main Ammo"?: string;
  "Flavour": string;
}

export interface WeaponCardProps {
  weapon: Weapon,
  imageBlob?: string,
  disableFilter?: boolean,
  onClick?: () => void, onContextMenu?: () => void
}

function toQualityArray(qualityObj: Record<string, number>): string[] {
  const pairs = Object.entries(qualityObj);
  return pairs.filter(([, value]) => value > 0).map(([key, value]) => key.replace(/X/, value.toString()))
}

//overflowClasses={["small", "small-flavour", "tiny", "red", "no-flavour"]}
export function WeaponCard({ weapon, imageBlob, disableFilter, onClick, onContextMenu }: WeaponCardProps) {
  const imageName = weapon.Name.replace(/\W/g, '').toLowerCase();
  const imageUrl = imageBlob ?? `./images/${imageName}.png`;
  return <div
    className={`card-wrapper ${weapon["Weapon Type"].toLowerCase().replace(/\W/g, '-')}`}>
    <div className="card" onClick={onClick} onContextMenu={onContextMenu}>
      <div className="overlay" />
      <OverflowStyled className="title_bar" loadingClass="loading" style={{}} overflowClasses={["smaller-title", "smaller-title-2", "smaller-title-3"]}>
        <div className="title" >
          <div className="prefix">{weapon.Prefix}</div>
          <div className="main_title">
            {weapon["Is Modded"] && <span>{'★ '}</span>}<span>{weapon.Name}</span>
          </div>
          <div className="sub_title">{weapon["Weapon Type"] + " "}{weapon["Ammo"] && <span className="ammo">  {weapon.Ammo}</span>}</div>
        </div>
        <div className="stat_bar misc">
          <div>
            <div>Cost</div>
            <div className="stat">{weapon["Cost"]}</div>
            <div className="sub_stat">caps</div>
          </div>
          <div>
            <div>Weight</div>
            <div className="stat">{weapon["Weight"]}</div>
            <div className="sub_stat">lbs</div>
          </div>
          <div>
            <div>Rarity</div>
            <div className="stat">{weapon["Rarity"]}</div>
          </div>
        </div>
      </OverflowStyled>
      <div className="stat_bar combat">
        <div>
          <div>Damage Dice</div>
          <div className="stat">{weapon["Damage Rating"]}</div>
          <div className="sub_stat">{weapon["Damage Type"]}</div>
        </div>
        {(weapon["Rate of Fire"] || weapon["Rate of Fire"] === 0) && <div>
          <div>Rate of Fire</div>
          <div className="stat">{weapon["Rate of Fire"]}</div>
        </div>}
        <div>
          <div>Range</div>
          <div className="stat">{weapon["Range"] || "-"}</div>
        </div>
      </div>
      <OverflowStyled
        className='main_area'
        loadingClass="loading"
        style={{}}
        overflowClasses={[
          "medium-font",
          "use-icon",
          "low-margins",
          "squeeze-text",
          "small-flavour",
          "tiny-flavour",
          "tiny-icon",
          "no-flavour",
          "no-icon",
          "small-rules",
          "super-low-margins",
          "tiny-font",
          "tiny-font-2",
          "tiny-font-3",
          "tiny-font-4",
          "tiny-font-5",
        ]}
      >
        <ColoredImage
          style={{}}
          disableFilter={disableFilter}
          src={imageUrl}
          className="image" />
        <Rules
          special={weapon.Rules}
          keywords={[
            ...toQualityArray(weapon.Qualities),
          ]}
          icon={imageUrl}
        />
        <div className="flavour">
          {weapon["Flavour"]}
        </div>
      </OverflowStyled>
      {weapon["Ammo"] && <div className="ammo_bar">
        <b>{weapon["Main Ammo"]}</b>
        <div className='ammo_alts'>
          {weapon['Ammo Alts'] && weapon['Ammo Alts'].length > 0 && weapon['Ammo Alts'].join && <>/{ weapon['Ammo Alts'].join("/")}</>}
        </div>
        <div className="ammo_details">
          {weapon["Ammo Cost"] && <>{weapon["Ammo Cost"] } Caps</>}
          {weapon["Ammo Cost"] && weapon["Ammo Rarity"] && <>, </>}
          {weapon["Ammo Rarity"] && <>{weapon["Ammo Rarity"] } Rarity</>}
        </div>
      </div>}
    </div>
  </div>
}
