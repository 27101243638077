import React from "react";
import { applyMod, WeaponMod } from "../mods";
import { ErrorCard } from "./ErrorCard";
import { Weapon, WeaponCard, WeaponCardProps } from "./Weapon";

export type ModdedWeaponCardProps = {
    mods?: WeaponMod[]
} & WeaponCardProps

const  ModdedWeaponCard = ({ weapon, mods = [], ...rest }: ModdedWeaponCardProps) => {
    const hasStock = mods.some(mod => mod.Slot === "Stocks");
    try{
        const moddedWeapon: Weapon = {
            ...mods.reduce((acc, mod) => applyMod(mod, acc), weapon),
            Name: (hasStock ? weapon["Name with Stock"] : weapon["Name without Stock"]) || weapon.Name,
        }
        return <WeaponCard weapon={moddedWeapon} {...rest} />;
    }catch(e){
       console.error(e)
        return <ErrorCard />;
    }
};

export default ModdedWeaponCard;


