import { useState } from "react";
import ContextMenuTarget from "../ContextMenu";
import { WeaponMod } from "../mods";
import { CustomizeWeapon } from "./CustomizeWeapon";
import ModdedWeaponCard from "./ModdedWeaponCard";
import { Weapon } from "./Weapon";
import React from "react";

export interface WeaponInstance{
    weapon: Weapon;
    mods: WeaponMod[];
    id: string;
    isCustom: boolean;
    imageBlob?: string;
    disableFilter?: boolean;
}

interface DynamicWeaponCardProps {
    weaponInstance: WeaponInstance;
    modList?: WeaponMod[];
    onDelete: ()=>void;
    onModsChange: (mods: WeaponMod[]) => void;
    onInstanceChange: (instance: WeaponInstance) => void;
}



interface ModMenuProps {
    modList: WeaponMod[];
    mods: WeaponMod[];
    toggleMod: (mod: WeaponMod) => void;
    onDelete: ()=>void
}

const ModMenu = ({ modList, mods, toggleMod, onDelete}: ModMenuProps) => {
    const modsBySlot = modList.reduce((acc, mod) => {
        acc[mod.Slot] = acc[mod.Slot] || [];
        acc[mod.Slot].push(mod);
        return acc;
    }, {} as { [slot: string]: WeaponMod[] });

    return <div className="mod-menu ui">
        {Object.keys(modsBySlot).map(slot => {
            const modList = modsBySlot[slot];
            return <div key={slot} className="mod-slot">
                <div className="slot-name">{slot}</div>
                {modList.map(mod => {
                    const isActive = mods.some(m => m.Name === mod.Name);
                    return <div key={mod.Name} className={`mod ${isActive ? "active" : ""}`} onClick={() => toggleMod(mod)}>
                        <span className="mod-name">{mod.Name}</span>
                        <span className="mod-desc"> ({mod.EffectText})</span>
                    </div>
                })}
            </div>
        })}
        <button onClick={onDelete}>✖ Delete</button>
    </div>
}

const DynamicWeaponCard = ({ weaponInstance, modList = [], onModsChange, onInstanceChange, onDelete, }: DynamicWeaponCardProps) => {
    const [blink, setBlink] = useState(false);

    const { weapon, mods, isCustom } = weaponInstance;
 
    return <ContextMenuTarget contextMenu={
       isCustom ? <CustomizeWeapon 
            instance={weaponInstance}
            onDelete={onDelete}
            onInstanceChange={(instance) => {
                onInstanceChange(instance);
                setBlink(true);
                window.requestAnimationFrame(() => setBlink(false));
            }}
        /> :  <>
        <ModMenu modList={modList} mods={mods} onDelete={onDelete} toggleMod={mod => {
            const isActive = mods.some(m => m.Name === mod.Name);
            if(isActive) {
                onModsChange(mods.filter(m => m.Name !== mod.Name));
            } else {
                onModsChange([...mods.filter(m => m.Slot !== mod.Slot), mod]);
            }
            setBlink(true);
            window.requestAnimationFrame(() => setBlink(false));
        }} />
        </>
    }>
        {blink ? <></> : <ModdedWeaponCard 
            weapon={weapon} 
            imageBlob={weaponInstance.imageBlob}
            disableFilter={weaponInstance.disableFilter}
            mods={mods} 
            key={JSON.stringify(modList)}
        />}
    </ContextMenuTarget>
};

export default DynamicWeaponCard;