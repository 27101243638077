import { WeaponInstance } from "./DynamicWeapon";
import Qualities from "../qualities.json"
import weapons from "../weapons.json"
import React from "react";
import { Weapon } from "./Weapon";
import { Quality } from "../mods";


export type CustomizeWeaponProps = {
    instance: WeaponInstance;
    onInstanceChange: (instance: WeaponInstance) => void;
    onDelete: () => void;
}

const Field = ({
    field, 
    instance, 
    onInstanceChange, 
    type
}: {field: keyof Weapon, instance: WeaponInstance, onInstanceChange: (instance: WeaponInstance) => void,
     type:"text"|"number"|"checkbox"|"select"|"textarea"
}) => {
    const {weapon} = instance;
    const [value, setValue] = React.useState(weapon[field]);
    const onChange = (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>) => {
        if(type === "checkbox"){
            setValue((e.target as any).checked);
        }
        else if(type === "number"){
            setValue(parseInt(e.target.value));
        }
        else setValue(e.target.value);
    }
    const onBlur = () => {
        onInstanceChange({
            ...instance,
            weapon: {
                ...weapon,
                [field]: value
            }
        })
    }

    if(type === "textarea"){
        return <label className={`${field.toLowerCase()}-field`}>
            {field}{' '}
            <br/>
            <textarea value={`${value}`} onChange={onChange} onBlur={onBlur}/>
        </label>
    }

    if(type === "select"){
        const options = weapons.map(w=>(w as any as Weapon)[field] as Weapon[keyof Weapon])
            .filter((v, i, a) => a.indexOf(v) === i)
            .sort()
            .map(v=>({value:v, label:v}));
        return <label className={`${field.toLowerCase()}-field`}>
            {field}{' '}
            <select value={`${value}`} onChange={onChange} onBlur={onBlur}>
                {options.map(o=>
                    <option key={`${o.value}`} value={`${o.value}`}>{`${o.label}`}</option>
                )}
             </select>
        </label>
    }

    return <label className={`${field.toLowerCase()}-field`}>{field}{' '}
        <input type={type} value={value as any} checked={!!value} onChange={onChange} onBlur={onBlur}/>
    </label>
}

const ImageUploader = ({
    instance,
    onInstanceChange
}: {instance: WeaponInstance, onInstanceChange: (instance: WeaponInstance) => void}) => {
  
    const onUpload =(e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if(!files) return;
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (e: any) => {
            onInstanceChange({
                ...instance,
                imageBlob: e.target.result
            })
        }
        if(file) reader.readAsDataURL(file);
    }
    return <fieldset>
        <legend>Image</legend>
        <label>
            Upload: <input type="file" onChange={onUpload}/>
        </label>
        <label>
            Disable Filter: <input type="checkbox" 
                checked={instance.disableFilter}
                onChange={(e) => {
                onInstanceChange({
                    ...instance,
                    disableFilter: e.target.checked
                })
            }}/>
        </label>
    </fieldset>
}

const QualityInput = ({
    instance,
    onInstanceChange,
    quality
}: {instance: WeaponInstance, onInstanceChange: (instance: WeaponInstance) => void, quality: Quality}) => {
    const isNumeric = quality.Name.endsWith(" X")
    return  <label key={quality.Name}>
    <input type={isNumeric ? "number" : "checkbox"}
     checked={instance.weapon.Qualities[quality.Name] > 0} 
     value={instance.weapon.Qualities[quality.Name]} 
     onChange={(e)=>{
         const value = isNumeric ? parseInt(e.target.value) : (e.target as any).checked;
        onInstanceChange({
            ...instance,
            weapon: {
                ...instance.weapon,
                Qualities: {
                    ...instance.weapon.Qualities,
                    [quality.Name]: value
                }
            }
        })
    }} />
    {quality.Name}
</label>
}

export const CustomizeWeapon = ({ instance, onInstanceChange, onDelete }: CustomizeWeaponProps) => {
    return <div className="customize-weapon ui">
        <Field field="Name" instance={instance} onInstanceChange={onInstanceChange} type="text"/>
        <Field field="Prefix" instance={instance} onInstanceChange={onInstanceChange} type="text"/>
        <Field field="Weapon Type" instance={instance} onInstanceChange={onInstanceChange} type="select"/>
        <Field field="Is Modded" instance={instance} onInstanceChange={onInstanceChange} type="checkbox"/>
        <Field field="Cost" instance={instance} onInstanceChange={onInstanceChange} type="number"/>
        <Field field="Weight" instance={instance} onInstanceChange={onInstanceChange} type="number"/>
        <Field field="Rarity" instance={instance} onInstanceChange={onInstanceChange} type="number"/>
        <Field field="Damage Rating" instance={instance} onInstanceChange={onInstanceChange} type="number"/>
        <Field field="Damage Type" instance={instance} onInstanceChange={onInstanceChange} type="select"/>
        <Field field="Rate of Fire" instance={instance} onInstanceChange={onInstanceChange} type="number"/>
        <Field field="Range" instance={instance} onInstanceChange={onInstanceChange} type="select"/>
        <Field field="Ammo" instance={instance} onInstanceChange={onInstanceChange} type="text"/>
        <Field field="Ammo Cost" instance={instance} onInstanceChange={onInstanceChange} type="text"/>
        <Field field="Ammo Rarity" instance={instance} onInstanceChange={onInstanceChange} type="text"/>
        <ImageUploader instance={instance} onInstanceChange={onInstanceChange}/>
        <fieldset className="customize-qualities">
            <legend>Qualities and Damage Effects</legend>
            {Qualities.map(q => <QualityInput key={q.Name} instance={instance} onInstanceChange={onInstanceChange} quality={q as any}/>)}
            
        </fieldset>
        <Field field="Rules" instance={instance} onInstanceChange={onInstanceChange} type="textarea"/>
        <Field field="Flavour" instance={instance} onInstanceChange={onInstanceChange} type="textarea"/>
        <button onClick={onDelete}>✖ Delete</button>
    </div>
}
