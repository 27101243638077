import ammo from './ammo.json';
import { Weapon } from "./WeaponCard/Weapon";


export function getAmmoFields(ammoName?: string) {
  const ammo_fields: Partial<Weapon> = {};
  if (ammoName) {
    const mainAmmo = ammoName.split(",")[0];
    const ammo_details = ammo.find(a => a.name === mainAmmo);
    if (ammo_details === undefined) {
      throw new Error(`Ammo type ${ammoName} not found`);
    }
    ammo_fields['Ammo Rarity'] = ammo_details.rarity === undefined ? undefined : ammo_details.rarity.toString();
    ammo_fields['Ammo Cost'] = ammo_details.cost === undefined ? undefined : ammo_details.cost.toString();
    ammo_fields['Ammo Alts'] = ammoName.split(',').map(a => a.trim()).filter(a => a != mainAmmo);
    ammo_fields['Main Ammo'] = mainAmmo;
  }
  return ammo_fields;
}
