import { getAmmoFields } from './getAmmoFields';
import data from './mods.json';
import overrides from './mods_overrides.json'
import _qualities from './qualities.json';
import { Weapon } from './WeaponCard/Weapon';

export interface Quality {
    Name: string;
    Effect: string;
    Opposite: string;
}

const qualities = _qualities as Quality[];

type Effect = {
    Type: string,
    Value: string | number,
};

export interface WeaponMod {
    Name: string;
    Prefix?: string;
    Weapons: string[];
    EffectText: string;
    Effects: Effect[];
    Slot: string;
    Cost: number;
}

type Overrides = {
    [weapon: string]: {
        [modType: string]: string[];
    };
};



function filterValid(mods:WeaponMod[]){
    return mods.filter(mod => mod.Name)
}

function toWeaponMod(mod: Record<string, any>): WeaponMod {
    try{
        const effects: Effect[] = (mod.Effects as string)
        .split(',')
        .map(effect => effect.trim())
        .map(toEffect);
    
        const cost = parseInt(mod.Cost);
        if(isNaN(cost)){
            throw new Error('Invalid cost');
        }
        return {
            ...mod as WeaponMod,
            EffectText: mod.Effects,
            Cost: cost,
            Weapons: (mod['Mod Applies to list'] as string).split(',').map(name => name.trim()),
            Effects:  effects,
        }
    }catch(e){
        console.error(mod)
        throw e
    }
   
}
const processOverrides = (mods: any[], overrides: Overrides): WeaponMod[] => {
    const updatedMods: any[] = [...mods];

    for (const weapon in overrides) {
        const weaponMods = overrides[weapon];
        for (const modType in weaponMods) {
            for (const modName of weaponMods[modType]) {
                const modIndex = updatedMods.findIndex(m => m.Name === modName);
                if (modIndex === -1) {
                    throw new Error(`Mod not found: ${modName}`);
                }
                if (updatedMods[modIndex]["Mod Applies to list"].includes(weapon)) {
                    continue; // If the weapon is already in the list, we don't need to add it again.
                }
                updatedMods[modIndex]["Mod Applies to list"] += `, ${weapon}`;
            }
        }
    }

    return updatedMods;
};


const weaponMods:WeaponMod[] = filterValid(processOverrides(data, overrides) as any).map(x => {
    try{
        return toWeaponMod(x)
    }catch(e){
        throw e;
    }
}).filter(x => x) as WeaponMod[];


function toEffect(string: string): Effect {
    try{
        if(string.match(/^gain/i) || string.match(/^lose/i)){
            const isAdd = string.match(/^gain/i);
            const what = string.split(/^gain|^lose/i)[1].trim();
            const quality = findQualityByName(what);
            if(quality){
                return {
                    Type: isAdd ? 'Add Quality' : 'Remove Quality',
                    Value: what
                }
            }
            throw new Error(`Unknown quality: ${what}`)
        }
        if(string.match(/^ammo/i)){
            const what = string.split(/ammo/i)[1].trim();
            return {
                Type: 'Ammo',
                Value: what
            }
        }
        if(string.match(/^damage type/i)){
            const what = string.split(/damage type/i)[1].trim();
            return {
                Type: 'Damage Type',
                Value: what
            }
        }
        if(string.match(/^plus/i) || string.match(/^minus/i) || string.match(/^set/i)){
            const isMinus = string.match(/^minus/i);
            const isSet = string.match(/^set/i);
            const valueString = string.match(/\d+/g)![0];
            const value = parseInt(valueString) * (isMinus ? -1 : 1);
            const what = string.split(valueString)[1].trim();
            if(['CD Damage', 'Range', 'Fire Rate'].includes(what)){
                return {
                    Type: isSet ? `Set ${what}` : `Modify ${what}`,
                    Value: value
                }
            }else{
                throw new Error(`Unknown stat: ${what}`);
            }
        }
        throw new Error(`Unknown effect: ${string}`)
    }catch(e){
        throw e
    }
}


function findQualityByName(what: string): Quality {
    const nameWithNumbersAsX = what.replace(/\d+/g, 'X');
    const result = qualities.find(q => q.Name === nameWithNumbersAsX);
    if(!result){
        throw new Error(`Unknown quality: ${what}`);
    }
    return result;
}

export function appendQualityToList(prev:Record<string, number>, quality: string): Record<string, number>{
    const {Opposite, Name} = findQualityByName(quality);
    const prevOpposite = prev[Opposite] ?? 0;
    const value = parseInt(quality.match(/\d/g)?.[0] ?? '1');
    if(prevOpposite > 0){
        return {
            ...prev,
            [Opposite]: prevOpposite - value,
        }
    }
    return {
        ...prev,
        [Name]: (prev[Name] ?? 0) + value,
    }
}

function removeQualityToList(prev: Record<string, number>, quality: string): Record<string, number>{
    const {Name} = findQualityByName(quality);
    return {
        ...prev,
        [Name]: (prev[Name] ?? 0) - 1,
    }
}

export function applyEffect({Type, Value}: Effect, weapon: Weapon): Weapon{
    if(Type === 'Add Quality'){
        return {
            ...weapon,
            Qualities: appendQualityToList(weapon.Qualities, Value as string)
        }
    }
    if(Type === 'Remove Quality'){
        return {
            ...weapon,
            Qualities: removeQualityToList(weapon.Qualities, Value as string)
        }
    }
    if(Type === 'Ammo'){
        if(typeof Value !== 'string') throw new TypeError(`Ammo must be a string`);
        return {
            ...weapon,
            Ammo: Value,
            ...getAmmoFields(Value)
        }
    }
    if(Type === 'Damage Type'){
        if(typeof Value !== 'string') throw new TypeError(`Damage Type must be a string`);
        return {
            ...weapon,
            "Damage Type": Value
        }
    }
    if(Type === 'Set CD Damage'){
        if(typeof Value !== 'number') throw new TypeError(`DR must be a number`);
        return {
            ...weapon,
            "Damage Rating": Value
        }
    }
    if(Type === 'Set Range'){
        throw new Error('Not implemented')
    }
    if(Type === 'Set Fire Rate'){
        if(typeof Value !== 'number') throw new TypeError(`Fire Rate must be a number`);
        return {
            ...weapon,
            "Rate of Fire": Value
        }
    }
    if(Type === 'Modify CD Damage'){
        if(typeof Value !== 'number') throw new TypeError(`DR must be a number`);
        return {
            ...weapon,
            "Damage Rating": weapon["Damage Rating"] + Value
        }
    }
    if(Type === 'Modify Range'){
        if(typeof Value !== 'number') throw new TypeError(`Range must be a number`);
        return {
            ...weapon,
            "Range": numberToRange(rangeToNumber(weapon["Range"]) + Value)
        }
    }
    if(Type === 'Modify Fire Rate'){
        if(typeof Value !== 'number') throw new TypeError(`Rate of Fire must be a string`);
        return {
            ...weapon,
            "Rate of Fire": (weapon["Rate of Fire"] ?? 0) + Value
        }
    }
    throw new Error(`Unknown type: ${Type}`)
}

export function applyMod(mod: WeaponMod, weapon:Weapon): Weapon{
    const {Prefix, Effects} = mod;
    const modified =  Effects.reduce((weapon, effect) => applyEffect(effect, weapon), weapon);
    if(typeof mod.Cost !== 'number'){
        throw new TypeError(`Cost must be a number`)
    }
    return {
        ...modified,
        Cost: weapon.Cost + mod.Cost,
        Rarity: weapon.Rarity + 1,
        Prefix: [weapon.Prefix, Prefix].filter(Boolean).join(' '),
        "Is Modded": true
    }
}

function numberToRange(number: number){
    if(number >= 4) return 'X';
    if(number <= 0) return 'C'
    const result = {
        1: 'C',
        2: 'M',
        3: 'L',
    }[number]
    if(!result){
        throw new Error(`Unknown number: ${number}`)
    }
    return result
}

function rangeToNumber(range: string){
    const result = {
        C: 1,
        M: 2,
        L: 3,
        X: 4,
    }[range]
    if(!result){
        throw new Error(`Unknown range: ${range}`)
    }
    return result
}

const weaponModsByWeapon = weaponMods.reduce((acc, mod) => {
    mod.Weapons.forEach(weapon => {
        if(!acc[weapon]){
            acc[weapon] = [];
        }
        acc[weapon].push(mod);
    });
    return acc;
}, {} as Record<string, WeaponMod[]>);
export default weaponModsByWeapon
