import React from "react";

export function ErrorCard() {
    return <div className="card-wrapper">
        <div style={{
            position: "absolute",
            top: "50%",
            fontSize: "200%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        }}>
            Oops, something went wrong!
        </div>
    </div>;
}
