import _weapons from "./weapons.json";
import React, { useEffect } from "react"
import weaponMods, { appendQualityToList } from "./mods";
import DynamicWeaponCard, { WeaponInstance } from "./WeaponCard/DynamicWeapon";
import TopBar from './TopBar'
import { Weapon } from "./WeaponCard/Weapon";
import { Header } from "./Header";
import { useGlobalState } from "./useGlobalState";
import { DEFAULT_WEAPONS } from "./DEFAULT_WEAPONS";
import { getAmmoFields } from "./getAmmoFields";

export default function App() {
  const allWeapons = (_weapons.map((weapon) => {
    const { Qualities, ...rest } = weapon;
    const list = [
      ...Qualities.split(",").map(s=>s.trim()).filter(s=>s.length > 0),
      ...weapon["Damage Effects"].split(",").map(s=>s.trim()).filter(s=>s.length > 0)
    ]
    const qualitiesObject = list.reduce((acc, cur) => appendQualityToList(acc, cur), {});
    const ammo_fields: Partial<Weapon> = getAmmoFields(rest['Ammo']);
    return {
      ...rest,
      ...ammo_fields,
      Qualities: qualitiesObject
    }
  })) as Weapon[];

  const [weapons, setWeapons] = useGlobalState<WeaponInstance[]>("weapons", DEFAULT_WEAPONS as any as WeaponInstance[]);
  const [pageStyles, setPageStyles] = useGlobalState<string[]>('print-settings', []);
  
  usePendo();
  
  return <div className={pageStyles.join(' ')}>
    <Header />
    <TopBar setWeapons={setWeapons} allWeapons={allWeapons} pageStyles={pageStyles} setPageStyles={setPageStyles} />
    {weapons.length === 0 && <span className="empty-state"> Weapons will appear here <br/> Use the tools above to select some weapons, or design your own... </span>}
    {weapons.map((instance) => (
      <DynamicWeaponCard 
          weaponInstance={instance} 
          key={instance.id} 
          modList={weaponMods[instance.weapon.Name]}
          onModsChange={(mods) => {
            setWeapons(weapons.map(w => w.id === instance.id ? {...w, mods} : w))
          }}
          onInstanceChange={(instance) => {
            setWeapons(weapons.map(w => w.id === instance.id ? instance : w))
          }}
          onDelete={()=>{
            setWeapons(weapons.filter(x=>x.id!=instance.id))
          }}
        />
    ))}
  </div>
}

const usePendo = () => {
  const [{pendoId}] = useGlobalState<{pendoId: string}>("pendo-id", {
    pendoId: randomUUID()
  });
  useEffect(() => {
    if ((window as any).pendo) {
      const pendo = (window as any).pendo;
      pendo.initialize({
        visitor: {
          id: pendoId
        }
      });
    }else{
      console.error("Pendo not found");
    }
  }, [pendoId]);
}

const randomUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  }).toUpperCase();
}
