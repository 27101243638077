import { useState } from "react";
import { WeaponInstance } from "../WeaponCard/DynamicWeapon";
import weaponMods, { applyMod } from "../mods";
import { Weapon } from "../WeaponCard/Weapon";
import React from "react";

interface TopBarProps {
    setWeapons: (mutator: (weapons: WeaponInstance[]) => WeaponInstance[]) => void;
    allWeapons: Weapon[];
    pageStyles: string[];
    setPageStyles: (mutator: (styles: string[]) => string[]) => void;
}

const createInstance = (weapon: Weapon): WeaponInstance => {
    const id = `${weapon.Name}-${Math.random()}`;
    return {
        id,
        weapon,
        mods: [],
        isCustom: false,
    };
};

const TopBar = ({ setWeapons, allWeapons, setPageStyles, pageStyles }: TopBarProps) => {
    const [maxCost, setMaxCost] = useState<number|undefined>(undefined);
    const [maxRarity, setMaxRarity] = useState<number|undefined>(undefined);

    const allWeaponsByType:Record<string, Weapon[]> = allWeapons.reduce((acc, w) => {
        acc[w["Weapon Type"]] = acc[w["Weapon Type"]] || [];
        acc[w["Weapon Type"]].push(w);
        return acc;
    }, {} as { [slot: string]: Weapon[] });

    const [selectedWeapon, setSelectedWeapon] = useState(allWeapons[0]["Name"])

    const addSelected = ()=>{
        const newWeapon = allWeapons.find(w=>w.Name === selectedWeapon)
        if(!newWeapon) throw new Error("Couldn't find weapon to add")
        setWeapons(prev=>[{
            ...createInstance(newWeapon),
        }, ...prev])
    }

    const addAll = ()=>{
        setWeapons(prev => [...allWeapons.map(createInstance), ...prev])
    }

    const getRandom = ()=>{
        const index = Math.floor(Math.random() * allWeapons.length);
        return allWeapons[index];
    }

    const clear = ()=>{
        setWeapons(_=>[])
    }

    const print = ()=>{
        window.print()
    }

    const toggleStyle = (style: string)=>{
        setPageStyles(prev=>{
            if(prev.includes(style)){
                return prev.filter(s=>s !== style)
            }else{
                return [...prev, style]
            }
        })
    }

    const getRandomModded = ()=>{
        const index = Math.floor(Math.random() * allWeapons.length);
        const newWeapon = allWeapons[index];
        const newInstance = createInstance(newWeapon);
        const modList = weaponMods[newWeapon.Name] || [];
        const slotList = modList.map(m=>m.Slot).filter((s, i, arr)=>arr.indexOf(s) === i);
        const maxMods = Math.min(3, slotList.length);
        const minMods = Math.min(1, slotList.length);
        const modCount = Math.floor(Math.random() * (maxMods - minMods + 1)) + minMods;
        const slots = slotList.sort(()=>Math.random() - 0.5).slice(0, modCount);
        const mods = slots.map(s=>{
            const modsInSlot = modList.filter(m=>m.Slot === s);
            const index = Math.floor(Math.random() * modsInSlot.length);
            return modsInSlot[index];
        });
        newInstance.mods = mods;
        return newInstance;
    }

    const addRandomModded = ()=>{
        for(let i = 0; i < 100; i++){
            const newInstance = getRandomModded();
            const { Rarity, Cost } = newInstance.mods.reduce((mod, acc) => applyMod(acc, mod), newInstance.weapon);
            if(maxCost && Cost > maxCost) continue;
            if(maxRarity && Rarity > maxRarity) continue;
            setWeapons(prev=>[newInstance, ...prev])
            return;
        }
    }

    const addRandom = ()=>{
        for(let i = 0; i < 100; i++){
            const newInstance = createInstance(getRandom());
            const { Rarity, Cost } = newInstance.mods.reduce((mod, acc) => applyMod(acc, mod), newInstance.weapon);
            if(maxCost && Cost > maxCost) continue;
            if(maxRarity && Rarity > maxRarity) continue;
            setWeapons(prev=>[newInstance, ...prev])
            return;
        }
    }

    const addCustom = ()=>{
        const weapon:Weapon = {
            "Name": "Custom Weapon",
            "Name without Stock": "",
            "Name with Stock": "",
            "Weapon Type": "Small Guns",
            "Damage Rating": 1,
            "Damage Type": "Energy",
            "Rate of Fire": undefined,
            "Qualities": {},
            "Weight": "1",
            "Cost": 1,
            "Range": "",
            "Rarity": 1,
            "Rules": "",
            "Ammo": "",
            "Flavour": ""
        }
        const newInstance:WeaponInstance = {
            mods: [],
            isCustom: true,
            id: `custom-${Math.random()}`,
            weapon
        }
        setWeapons(prev => [newInstance, ...prev])
    }

    return <div className="top-bar ui">
            <div>
                <fieldset>
                    <legend>About</legend>
                    <div className="about-text">
                        <p>
                            This tool by <b>Mr. Salmon</b> (<a href="https://www.reddit.com/user/escaperoommaster/"><img src="images/reddit.png"/></a>
                            <a href="https://discord.com/channels/828289443752181812/828289956320641095/982416512000335914"><img src="images/discord.png"/></a>) is 
                            for DMs and players of <b>Fallout 2d20 Tabletop RPG</b> to create
                            weapon reference cards. 
                        </p>
                        <p>
                            Frankly, I found trying to give out weapons as loot incredibly fiddly without these references cards.
                        </p>
                        <p>
                            All weapons and mods from the core book as accuruately as I 
                            can <b>Right click on weapon cards to edit mods</b> and <b>Right click on custom cards to edit them</b>.
                        </p>
                        <p>
                            To ensure printing works correctly,
                            you'll need to enable <b>Print Backgrounds</b> in the <b>Print Settings</b>
                        </p>
                        <p>
                            Now supporting weapons from the <a href="https://www.modiphius.net/products/fallout-the-roleplaying-game-settlers-supplement">Settler's Supplement (🏘️)!</a>
                        </p>
                    </div>
            </fieldset>
            <fieldset>
                <legend>Settings</legend>
                <label>
                    <input
                        type="checkbox"
                        checked={pageStyles.includes("disable-background")}
                        onChange={()=>toggleStyle("disable-background")}
                    />
                    Disable Textures
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={pageStyles.includes("bleed-area")}
                        onChange={()=>{
                            toggleStyle("bleed-area")
                            
                        }}
                    />
                    Add Bleed Area (for proffesional printing)
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={pageStyles.includes("disable-colors")}
                        onChange={()=>toggleStyle("disable-colors")}
                    />
                    Disable Colors
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={pageStyles.includes("old-colors")}
                        onChange={()=>toggleStyle("old-colors")}
                    />
                    Old Colors
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={pageStyles.includes("reduce-ammo-info")}
                        onChange={()=>toggleStyle("reduce-ammo-info")}
                    />
                    Reduce Ammo Information
                </label>
            </fieldset>
            </div>
        <div className="tools">
        <fieldset>
            <legend>Select Weapons</legend>
            <select value={selectedWeapon} onChange={e=>setSelectedWeapon(e.target.value)}>
                {Object.entries(allWeaponsByType).map(([group, weapons])=>
                    <optgroup label={group} key={group}>
                        {weapons.map(w=>
                            <option key={w.Name} value={w.Name}>{w.Name} {w.Book}</option>    
                        )}
                    </optgroup>)
                }
            </select>
            <br/>
            <button onClick={addSelected}>Add Weapon</button>
            <button onClick={addAll}>Add all Weapons</button>
        </fieldset>
        <fieldset>
            <legend>Randomized Weapons</legend>
            <label>
                Max Cost 
                <input type="number" value={maxCost} onChange={e=>setMaxCost(parseInt(e.target.value))}/>
            </label>
            <br/>
            <label>
                Max Rarity
                <input type="number" value={maxRarity} onChange={e=>setMaxRarity(parseInt(e.target.value))}/>
            </label>
            <br/>
            <button onClick={addRandom}>Add random Weapon</button>
            <button onClick={addRandomModded}>Add random modded Weapon</button>
        </fieldset>
        <fieldset>
            <legend>Custom Weapons</legend>
            <button onClick={addCustom}>Add Custom</button>
        </fieldset>
        <fieldset>
            <legend>Misc.</legend>
            <button onClick={clear}>Clear</button>
            <button onClick={print}>Print</button>
        </fieldset>
        </div>
    </div>
}

export default TopBar;

