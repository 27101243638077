import { useState, useEffect } from "react";

type useGlobalStateReturn<T extends object> = [
    T,
    (newState: ((prev:T)=>T)|T) => void,
]

export const useGlobalState = <T extends object>(
    key: string, 
    defaultValue: T,
    transformerIn: (value: T) => string = (value: T) => JSON.stringify(value),
    transformerOut: (value: string) => T = (value: string) => JSON.parse(value),
): useGlobalStateReturn<T> => {
   const [value, setValue] = useState<T>(() => {
        const value = localStorage.getItem(key);
        const initialValue =  value ? transformerOut(value) : defaultValue;
        if(initialValue){
            localStorage.setItem(key, transformerIn(initialValue));
        }
        return initialValue;
     });

    const onLocalStorageChange = (event: StorageEvent) => {
        if (event.key === key) {
            const value = event.newValue
            setValue(value ? transformerOut(value) : defaultValue);
        }
    };

    useEffect(() => {
        window.addEventListener('storage', onLocalStorageChange);
        return () => {
            window.removeEventListener('storage', onLocalStorageChange);
        };
    });


    const externalSetValue = (newState: ((prev:T)=>T)|T) => {
        if(typeof newState === 'object') {
            localStorage.setItem(key, transformerIn(newState));
            setValue(newState);
        } else {
            localStorage.setItem(key, transformerIn(newState(value)));
            setValue(newState(value));
        }
    }

    return [value as T, externalSetValue];
};

    