import React, { useEffect, useState, useRef, useMemo } from 'react';

interface ContextMenuTargetProps {
    children: React.ReactElement;
    contextMenu: React.ReactElement;
}

const isParentWithId = (element: HTMLElement, id: string): boolean => {
    while (element) {
        if (element.id === id) return true;
        element = element.parentElement as HTMLElement;
    }
    return false;
};

const ContextMenuTarget: React.FC<ContextMenuTargetProps> = ({ children, contextMenu }) => {
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
    const elementRef = useRef<HTMLDivElement | null>(null);
    const uniqueId = useMemo(() => Math.random().toString(36).substring(2), []);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setIsContextMenuOpen(true);
        const { clientX, clientY } = event;
        const minY = window.innerHeight - (elementRef.current?.clientHeight ?? 0) - 10;

        setContextMenuPosition({ x: clientX, y: Math.min(clientY, minY) });
    };

    const handleContextMenuClose = () => {
        setIsContextMenuOpen(false);
    };

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (!isContextMenuOpen) return;
            if (!elementRef.current) throw new Error('Element not found');
            const isClickInside = isParentWithId(event.target as HTMLElement, uniqueId);
            if (!isClickInside) {
                handleContextMenuClose();
            }
        };
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, [isContextMenuOpen, uniqueId]);

    const contextMenuStyle: React.CSSProperties = useMemo(
        () => ({
            position: 'fixed',
            top: contextMenuPosition.y,
            left: contextMenuPosition.x,
            zIndex: 100,
            visibility: isContextMenuOpen ? 'visible' : 'hidden',
        }),
        [contextMenuPosition, isContextMenuOpen]
    );

    const ContextMenu = useMemo(() => {
        return React.forwardRef<HTMLDivElement>((_, ref) => (
            <div id={uniqueId} ref={ref} style={contextMenuStyle} className="context-menu">
                {contextMenu}
            </div>
        ));
    }, [contextMenu, contextMenuStyle, uniqueId]);

    return (
        <>
            {React.cloneElement(children, {
                onContextMenu: handleContextMenu,
            })}
            <ContextMenu ref={elementRef} />
        </>
    );
};

export default ContextMenuTarget;
