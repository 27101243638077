export const DEFAULT_WEAPONS = [
  {
    id: "Frag Grenade-0.30696289233140783",
    weapon: {
      Name: "Frag Grenade",
      "Name without Stock": "",
      "Name with Stock": "",
      "Weapon Type": "Explosive",
      "Damage Rating": 6,
      "Damage Effects": "",
      "Damage Type": "Physical",
      "Rate of Fire": null,
      Weight: "<1",
      Cost: 50,
      Range: "M",
      Rarity: 2,
      Rules: "",
      Ammo: "",
      Flavour:
        "A basic fragmentation grenade, consisting of an explo sive core within a metal casing designed to shatter into shrapnel upon detonation. They're simple and highly effective. Just remember to throw the grenade, and not the pin.",
      Qualities: { Blast: 1, Thrown: 1 },
    },
    mods: [],
    isCustom: false,
  },
  {
    id: "Power Fist-0.6485597949444504",
    weapon: {
      Name: "Power Fist",
      "Name without Stock": "",
      "Name with Stock": "",
      "Weapon Type": "Unarmed",
      "Damage Rating": 4,
      "Damage Effects": "Stun",
      "Damage Type": "Physical",
      "Rate of Fire": null,
      Weight: "4",
      Cost: 100,
      Range: "",
      Rarity: 2,
      Rules: "",
      Ammo: "",
      Flavour:
        "A Power Fist is a reinforced gauntlet or vambrace which mounts a heavy-duty pneumatic ram over the knuckles. Designed for use by demolition crews, it has also seen military use for breaching fortifications and clearing enemy barricades... and enemies.",
      Qualities: { Stun: 1 },
    },
    mods: [
      {
        Name: "Heating Coil",
        Prefix: "Heated",
        Slot: "Uniques",
        Complexity: 4,
        "Perk 1": "Blacksmith 3",
        "Perk 2": "",
        Skill: "Repair",
        Rarity: "Uncommon",
        Materials: "Common x 5 Uncommon x 3",
        Cost: 100,
        Effects: [
          { Type: "Modify CD Damage", Value: 2 },
          { Type: "Damage Type", Value: "Energy" },
        ],
        Weight: null,
        "Mod Applies to list": "Power Fist",
        EffectText: "plus 2 CD Damage, Damage Type Energy",
        Weapons: ["Power Fist"],
      },
    ],
    isCustom: false,
  },
  {
    id: "Pipe Gun-0.2682714250724738",
    weapon: {
      Name: "Pipe Gun",
      "Name without Stock": "Pipe Gun",
      "Name with Stock": "Pipe Rifle",
      "Weapon Type": "Small Guns",
      "Damage Rating": 3,
      "Damage Effects": "",
      "Damage Type": "Physical",
      "Rate of Fire": 2,
      Weight: "2",
      Cost: 30,
      Range: "C",
      Rarity: 0,
      Rules: "",
      Ammo: ".38",
      Flavour:
        "A crude, homemade weapon, normally seen in the hands of those who can't make, buy or scavenge anything better, pipe guns are crude and somewhat unreliable, but effective enough. The most common form of pipe gun is a simple little semi-auto hand gun, chambered for the common 38 round, but enterprising (or desperate) souls have been known to modify these guns for automatic fire or precision sniping",
      Qualities: { "Close Quarters": 1, Unreliable: 1 },
    },
    mods: [],
    isCustom: false,
  },
  {
    id: "10mm Pistol-0.7639143590274438",
    weapon: {
      Name: "10mm Pistol",
      "Name without Stock": "",
      "Name with Stock": "",
      "Weapon Type": "Small Guns",
      "Damage Rating": 4,
      "Damage Effects": "",
      "Damage Type": "Physical",
      "Rate of Fire": 2,
      Weight: "4",
      Cost: 50,
      Range: "C",
      Rarity: 1,
      Rules: "",
      Ammo: "10mm",
      Flavour:
        "Small, dependable, reasonably powerful, and available, the 10mm pistol has been a staple widely the bombs first fell. Its wasteland combat since of customization mean and potential for that a versatility will keep its owner well-looked-after 10mm pistol safe for a long time.",
      Qualities: { "Close Quarters": 1, Reliable: 1 },
    },
    mods: [
      {
        Name: "Sharpshooter's Grip",
        Prefix: "Sharpshooter's",
        Slot: "Grips",
        Complexity: 3,
        "Perk 1": "Gun Nut 1",
        "Perk 2": "",
        Skill: "Repair",
        Rarity: "Uncommon",
        Materials: "Common x 4 Uncommon x 2",
        Cost: 10,
        Effects: [
          { Type: "Add Quality", Value: "Piercing 1" },
          { Type: "Remove Quality", Value: "Inaccurate" },
        ],
        Weight: null,
        "Mod Applies to list":
          "10mm Pistol, Pipe Bolt-Action, Pipe Gun, Pipe Revolver",
        EffectText: "gain Piercing 1, lose Inaccurate",
        Weapons: [
          "10mm Pistol",
          "Pipe Bolt-Action",
          "Pipe Gun",
          "Pipe Revolver",
        ],
      },
      {
        Name: "Recon Scope",
        Prefix: "Recon",
        Slot: "Sights",
        Complexity: 5,
        "Perk 1": "Science! 3",
        "Perk 2": "",
        Skill: "Repair ",
        Rarity: "Uncommon",
        Materials: "Common x 6 Uncommon x 4 Rare x 2",
        Cost: 59,
        Effects: [
          { Type: "Add Quality", Value: "Accurate" },
          { Type: "Add Quality", Value: "Recon" },
        ],
        Weight: 1,
        "Mod Applies to list":
          ".44 Pistol, 10mm Pistol, Assault Rifle, Combat Rifle, Gauss Rifle, Hunting Rifle, Combat Shotgun, Pipe Bolt-Action, Pipe Gun, Pipe Revolver, Railway Rifle, Syringer",
        EffectText: "gain Accurate, gain Recon",
        Weapons: [
          ".44 Pistol",
          "10mm Pistol",
          "Assault Rifle",
          "Combat Rifle",
          "Gauss Rifle",
          "Hunting Rifle",
          "Combat Shotgun",
          "Pipe Bolt-Action",
          "Pipe Gun",
          "Pipe Revolver",
          "Railway Rifle",
          "Syringer",
        ],
      },
    ],
    isCustom: false,
  },
];
