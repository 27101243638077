import React, { useEffect, createContext, useState, useContext } from "react";
const THRESHHOLD = 10;

const OverflowContext = createContext({
  childOverflow: false,
  setChildOverflow: () => {},
});

export default function OverflowStyled({
  overflowClasses,
  children,
  className,
  loadingClass,
  style
}) {
  const [remainingClasses, setRemainingClasses] = React.useState(
    overflowClasses
  );
  const [activeClasses, setActiveClasses] = React.useState([className]);
  const [fontsLoaded, setFontsLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const ref = React.useRef(null);

  const [childOverflow, setChildOverflow] = useState(false);
  const contextValue = { childOverflow, setChildOverflow };
  const parentContext = useContext(OverflowContext);

  useEffect(() => {
    if (childOverflow && parentContext.setChildOverflow) {
      parentContext.setChildOverflow(true);
    }
  }, [childOverflow, parentContext]);

  useEffect(()=>{
    document.fonts.ready.then(()=>{
      setFontsLoaded(true);
    });
  }, [])

  React.useEffect(() => {
    if (ref !== null && ref.current && fontsLoaded) {
      const progressIfOverflowing = () => {
        const el = ref.current;
        if (
          !childOverflow &&
          el.clientHeight < el.scrollHeight - THRESHHOLD
        ) {
          if (remainingClasses.length === 0) return;
          const toAdd = remainingClasses[0];
          setActiveClasses([...activeClasses, toAdd]);
          setRemainingClasses(remainingClasses.filter((c) => c !== toAdd));
        } else {
          setLoading(false);
          if (childOverflow && parentContext.setChildOverflow) {
            parentContext.setChildOverflow(false);
          }
        }
      };
      const id = setInterval(progressIfOverflowing, 150);
      return () => clearInterval(id);
    }
  }, [ref, remainingClasses, activeClasses, fontsLoaded]);

  if(!fontsLoaded) return null;

  return (
    <OverflowContext.Provider value={contextValue}>
      <div style={style} ref={ref} className={[...activeClasses, ...(loading ? [loadingClass] : [])].join(" ")}>
        {children}
      </div>
    </OverflowContext.Provider>
  );
}
