import qualities from "./qualities.json";

const sortRulesByLength = (rules) => {
  return Object.entries(rules).sort((a, b) => a[1].length - b[1].length);
};

const Rule = ({ name, text }) => (
  <div className="rule" key={name}>
    <b>{name}:</b> <span>{text}</span>
  </div>
);
export default function Rules(props) {
  const rules = {
    ...(props.special && { Special: props.special })
  };

  if (props.keywords) {
    props.keywords.forEach((keywordRaw) => {
      const keyword = keywordRaw.trim();
      if (!keyword) return;
      const ruling = qualities.find((q) => q.Name === keyword.replace(/\d/g, "X"));
      const number = keyword.match(/\d+/)?.[0];
      rules[keyword] = ruling ? ruling.Effect.replace(/(\W)X(\W)/, `$1${number}$2`) : "???";
    });
  }

  return <>
  {sortRulesByLength(rules).map(([name, text]) => (
    <Rule name={name} text={text} />
  ))}
</>
}
