import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import CookieConsent  from "react-cookie-consent";
import './styles.sass';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
    <App />
    <CookieConsent>
      This website uses cookies to <strong>anonmously</strong> track users to improve the app and fix bugs <br/>
      <span style={{ fontSize: "80%" }}>
         I, mean, this site makes little Fallout reference cards it's not like I'm getting any valuable data from you...
      </span>
    </CookieConsent>
  </React.StrictMode>
);

